import React from "react"
import { graphql } from "gatsby"
import { Row, Col, Container } from "reactstrap"
import Link from "../components/link"
import Layout from "../templates/layout/layout"
import SEO from "../templates/layout/seo"
import { FaEnvelope } from "react-icons/fa"
import Styled from "styled-components"
// import Slider from "../components/slider"

let Cirle = Styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

let ContactItem = ({ text, type, Icon, href }) => (
  <Col
    xs={6}
    lg={3}
    className="d-flex flex-column align-items-center"
    style={{ wordBreak: "break-word" }}
  >
    <Cirle className="h-4-rem w-4-rem bg-primary-faded my-3 absolute-center rounded-circle">
      <Icon size={30} />
    </Cirle>
    <div className="text-center">
      <h6>{type}</h6>
      <span className="text-secondary">
        {href ? (
          <Link to={href} className="reset" style={{ color: "black" }}>
            {text}
          </Link>
        ) : (
          text
        )}
      </span>
    </div>
  </Col>
)

let Contact = ({ data }) => {
  let { address, email, phone, fax } = data.site.siteMetadata
  return (
    <Layout>
      <SEO title="Kontakt" />
      {/* <PageTitle title="Contact Us" /> */}
      {/* <Slider /> */}
      <Container fluid>
        <Row className="my-5 d-flex align-items-center justify-content-center align-self-stretch">
          <div>
            <h3>Kontaktiere uns</h3>
          </div>
        </Row>
        <hr />
        <Row className="py-5 justify-content-center ">
          {/* <ContactItem text={address} type="Address" Icon={FaMapMarkerAlt} /> */}
          <ContactItem
            text={email}
            href={`mailto:${email}`}
            type="Email"
            Icon={FaEnvelope}
          />
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Contact {
    site {
      siteMetadata {
        address
        email
        phone
        fax
      }
    }
  }
`

export default Contact
